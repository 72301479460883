<template>
  <div class="ml-1 mr-4">
    <a href="javascript:void(0);" @click="runSelected()" id="restartSelected">
      <v-icon class="mr-1"> mdi-restart </v-icon>
      <span
        style="position: relative; top: 2px"
        v-if="$vuetify.breakpoint.mdAndUp"
        >{{ language.componentLabel.labelRS }}</span
      ></a
    >
    <startParametersDialog
      v-if="parameterDialog"
      v-model="startParameters"
      :parameterDialog="parameterDialog"
      :loading="loading"
      @confirmation-ok="runTest"
      :testNames="testNames"
    >
    </startParametersDialog>
    <confirmationDialog
      :hideCancelButton="true"
      :value="showErrorDialog"
      title="Error"
      message="Error Running test. Please retry later"
      @confirmation-ok="showErrorDialog = !showErrorDialog"
      maxWidth="290"
    ></confirmationDialog>
  </div>
</template>
<script>
import startParametersDialog from "@/components/legacy/startParametersDialog.vue";
import confirmationDialog from "@/commonComponents/confirmationDialog.vue";
export default {
  props: ["result", "additional"],
  components: {
    startParametersDialog,
    confirmationDialog,
  },
  data() {
    return {
      parameterDialog: false,
      startParameters: [],
      loading: false,
      showErrorDialog: false,
    };
  },
  methods: {
    runTest(execute) {
      if (execute) {
        this.runSelected();
      } else {
        this.parameterDialog = false;
      }
    },
    runSelected() {
      let selected = [];

      Object.values(this.additional.selectedRows).forEach((row) => {
        selected.push(parseInt(row.n_id));
      });
      var options = {
        function: "restartSelectedTest",
        selected: selected,
        requestType: "ajax",
        startParameters: this.startParameters,
      };
      //console.log(this.result);
      if (typeof this.result["groupID"] != "undefined") {
        options["groupID"] = parseInt(this.result["groupID"]);
      }

      if (selected.length > 0) {
        let caller = this;
        this.$emit("show-warning", false);
        var URL = "serve.php?f=testing&f2=testsExecuteApi";
        this.frameworkAxiosRequest({
          method: 'POST',
          url: URL,
          dataType: 'json',
          data: options,
        })
            .then((response) => {
            if (response.data.result.responseCode == "201") {
              let route;
              if (typeof response.data.result.json != "undefined") {
                route = response.data.result.json.redirect;
              } else {
                route = response.data.result.redirect;
              }
              // insert a key in the in url to force change
              let saveLifeString = Math.random().toString(36).substring(7);
              caller.$router
                .push(route + "&" + saveLifeString)

                .catch(() => {
                  caller.$router.go();
                })
                .then(() => {
                  // clear the key
                  history.pushState({}, null, route);
                });
              caller.parameterDialog = false;
            } else if (response.data.result.responseCode == "300") {
              caller.parameterDialog = true;
              // console.log(response.data.result.body);
              caller.startParameters =
                response.data.result.body.startParameters;
                 caller.testNames = response.data.result.testNames;
            } else {
              caller.showErrorDialog = true;
              caller.parameterDialog = false;
              caller.startParameters = [];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.$emit("show-warning", true);
      }
    },
  },
};
</script>